import { Component } from "@angular/core";
import { PopoverController } from "@ionic/angular";

import { BaseComponent } from "../../../../base/components/base-component";
import { ProcessElementTriggeredEventArguments } from "../../../../base/interfaces/process-element-triggered-event-arguments";
import { DialogService } from "../../../../base/services/dialog/dialog.service";
import { ModalModes } from "../../../../base/services/dialog/modal-modes";
import { StorageInfoPage } from "../../../pages/storage-info/storage-info.page";
import { AppService } from "../../../services/app/app.service";
import { AppState } from "../../../services/app/app-state";
import { AttachmentLoaderService } from "../../../services/attachments/attachment-loader.service";
import { CachingService } from "../../../services/caching/caching.service";
import { DeviceService } from "../../../services/device/device.service";
import { DocumentQueuedUpdateService } from "../../../services/document-update/document-queued-update.service";
import { DocumentSynchronizationSummary } from "../../../services/documents/document-synchronization-summary";
import { I18nTextService } from "../../../services/i18n/i18n-text.service";
import { AppIcons } from "../../../services/icons/app-icons";
import { NetworkConnectionInformation } from "../../../services/monitors/network-connection-information";
import { OnlineMonitorService } from "../../../services/monitors/online-monitor.service";
import { NavigationService } from "../../../services/navigation/navigation.service";
import { SessionService } from "../../../services/session/session.service";
import { NetworkTestComponent } from "../../network-test/network-test.component";
import { SyncErrorListComponent } from "../sync-error-list/sync-error-list.component";
import { SyncErrorListOptions } from "../sync-error-list/sync-error-list-options";

/**
 * Popover with detailed information about the synchronization status.
 */
@Component({
    selector: "business-cloud-status-popover",
    templateUrl: "./cloud-status-popover.component.html",
    styleUrls: ["./cloud-status-popover.component.scss"]
})
export class CloudStatusPopoverComponent extends BaseComponent {
    constructor(
        private readonly onlineMonitorService: OnlineMonitorService,
        private readonly documentService: DocumentQueuedUpdateService,
        private readonly cachingService: CachingService,
        private readonly appService: AppService,
        private readonly sessionService: SessionService,
        private readonly deviceService: DeviceService,
        private readonly navigationService: NavigationService,
        private readonly dialogService: DialogService,
        private readonly popoverController: PopoverController,
        private readonly attachmentLoaderService: AttachmentLoaderService,
        private readonly i18nTextService: I18nTextService
    ) {
        super();

        this.appState = this.appService.appState;
        this.deviceId = this.deviceService.deviceIdentifier;
        this.sessionId = this.sessionService.appSession;
    }

    public readonly appIcons: typeof AppIcons = AppIcons;

    public appState: AppState;

    public onlineStateAlreadyChecked: boolean = false;

    public online: boolean = false;

    public latency: number = 0;

    public clientVersion: string = "2.26.1+1105.scroll-to-next";

    public serverVersion?: string;

    public cachingDisabled: boolean = false;

    public synchronizationSummary: DocumentSynchronizationSummary = new DocumentSynchronizationSummary();

    public uploadQueuePendingItems: number = this.attachmentLoaderService.queueItemsCount;

    public networkInformation!: NetworkConnectionInformation;

    public deviceId: string;

    public sessionId: string;

    public clearingCacheActive: boolean = false;

    public infoMessage?: string;

    public extendedInformationVisible: boolean = false;

    protected componentInit(): void {
        this.cachingDisabled = !this.cachingService.cachingActive;

        this.subscribe(this.onlineMonitorService.onlineStateChanged, this.onlineStateChanged);
        this.subscribe(this.onlineMonitorService.latencyChanged, this.latencyChanged);
        this.subscribe(this.onlineMonitorService.serverVersionChanged, this.serverVersionChanged);
        this.subscribe(this.onlineMonitorService.networkInformationChanged, this.networkInformationChanged);
        this.subscribe(this.documentService.synchronizationSummaryUpdated, this.synchronizationUpdated);
        this.subscribe(this.attachmentLoaderService.uploadQueueChanged, this.uploadQueueChanged);

        this.subscribe(this.cachingService.clearingCacheActiveChanged, this.clearingCacheActiveChanged);
        this.subscribe(this.cachingService.infoMessageUpdated, this.cacheInfoMessageUpdated);

        this.infoMessage = this.cachingService.infoMessage;
        this.clearingCacheActive = this.cachingService.clearingCacheActive;

        this.onlineStateAlreadyChecked = this.onlineMonitorService.alreadyChecked;
        this.online = this.onlineMonitorService.online;
        this.latency = this.onlineMonitorService.latency;
        this.serverVersion = this.onlineMonitorService.serverVersion;
        this.synchronizationSummary = this.documentService.summary;

        this.networkInformation = this.onlineMonitorService.networkInformation;
    }

    protected componentDestroy(): void {
        // Do nothing for now
    }

    private onlineStateChanged(online: boolean): void {
        this.onlineStateAlreadyChecked = true;
        this.online = online;
    }

    private latencyChanged(latency: number): void {
        this.latency = latency;
    }

    private networkInformationChanged(networkInformation: NetworkConnectionInformation): void {
        this.networkInformation = networkInformation;
    }

    private synchronizationUpdated(summary: DocumentSynchronizationSummary): void {
        this.synchronizationSummary = summary;
    }

    private serverVersionChanged(version: string): void {
        this.serverVersion = version;
    }

    public async cachingChanged(event: Event): Promise<void> {
        const customEvent: CustomEvent = event as CustomEvent;
        const detail: { checked: boolean } = customEvent.detail as { checked: boolean };
        const disabled: boolean = detail?.checked || false;

        this.cachingDisabled = disabled;

        await this.cachingService.activateCaching(!disabled);
    }

    public async clearCache(_triggeredEventArguments: ProcessElementTriggeredEventArguments): Promise<void> {
        await this.cachingService.clearCache();
        await this.dialogService.showAlert($localize`:@@cloudStatus.cacheClearedTitle:Cache cleared`, $localize`:@@cloudStatus.cacheClearedText:The has to be restarted now.`);
        await this.appService.restartApp(false);
    }

    public async openStorageInfo(triggeredEvent: ProcessElementTriggeredEventArguments): Promise<void> {
        triggeredEvent.finishedReceiver.finished();
        await this.popoverController.dismiss().then();
        await this.dialogService.showDefaultModal($localize`:@@storageInfo.dialogTitle:Storage information`, { component: StorageInfoPage });
    }

    public async showSyncQueue(event?: Event): Promise<void> {
        event?.stopPropagation();
        this.popoverController.dismiss().then();

        await this.dialogService.showDefaultModal($localize`:@@cloudStatus.syncQueueDialogTitle:Synchronization Queue`, {
            component: SyncErrorListComponent,
            options: new SyncErrorListOptions()
        },
        {
            mode: ModalModes.large,
            singleInstance: true
        });
    }

    private uploadQueueChanged(pending: number): void {
        this.uploadQueuePendingItems = pending;
    }

    public booleanToYesNo(value: boolean): string {
        return this.i18nTextService.booleanToYesNo(value);
    }

    public installPwa(): void {
        this.appService.installPwa();
    }

    private clearingCacheActiveChanged(active: boolean): void {
        this.clearingCacheActive = active;
    }

    private cacheInfoMessageUpdated(message: string): void {
        this.infoMessage = message;
    }

    public showExtendedInformation(): void {
        this.extendedInformationVisible = true;
    }

    public async networkTest(triggeredEvent?: ProcessElementTriggeredEventArguments): Promise<void> {
        this.dialogService.showDefaultModal($localize`:@@networkTest.dialogTitle:Network test`, { component: NetworkTestComponent }, { mode: ModalModes.large }).then();
        await this.popoverController.dismiss();
        triggeredEvent?.finishedReceiver.finished();
    }
}
