import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from "@angular/router";
import { Subscription } from "rxjs";

import { ContextMenuItem } from "../../../base/components/context-menu/context-menu-item";
import { ShareUrlComponent } from "../../../base/components/share-url/share-url.component";
import { EventHelper } from "../../../base/helpers/event-helper";
import { ImageHelper } from "../../../base/helpers/image-helper";
import { WebHelper } from "../../../base/helpers/web-helper";
import { ProcessElementTriggeredEventArguments } from "../../../base/interfaces/process-element-triggered-event-arguments";
import { DialogService } from "../../../base/services/dialog/dialog.service";
import { IntentsService } from "../../../base/services/intents/intents.service";
import { ScreenshotService } from "../../../base/services/screenshot/screenshot.service";
import { TimeService } from "../../../base/services/time/time.service";
import { AccountDto } from "../../../generated/api";
import { Person } from "../../entities/core-data/person";
import { ShowDebugDialogIntent } from "../../intents/show-debug-dialog-intent";
import { Pages } from "../../pages/pages";
import { AppService } from "../../services/app/app.service";
import { AppState } from "../../services/app/app-state";
import { OnlineHelpService } from "../../services/help/online-help.service";
import { AppIcons } from "../../services/icons/app-icons";
import { MenuService } from "../../services/menu/menu.service";
import { MenuOptions } from "../../services/menu/menu-options";
import { NavigationService } from "../../services/navigation/navigation.service";
import { SessionService } from "../../services/session/session.service";
import { SessionChangedEventData } from "../../services/session/session-changed-event-data";
import { AccountPopoverComponent } from "../account-popover/account-popover.component";
import { AccountSwitchPopoverComponent } from "../account-switch-popover/account-switch-popover.component";
import { QuickAddComponent } from "../quick-add/quick-add.component";

/**
 * The header for all main screens. It allows opening the burger menu on mobile and other actions like quick-adding content.
 */
@Component({
    selector: "business-page-header",
    templateUrl: "./page-header.component.html",
    styleUrls: ["./page-header.component.scss"]
})
export class PageHeaderComponent implements OnInit, OnDestroy {
    constructor(
        private readonly sessionService: SessionService,
        private readonly dialogService: DialogService,
        private readonly intentService: IntentsService,
        private readonly appService: AppService,
        private readonly navigationService: NavigationService,
        private readonly router: Router,
        private readonly menuService: MenuService,
        private readonly screenshotService: ScreenshotService,
        private readonly timeService: TimeService,
        private readonly onlineHelpService: OnlineHelpService,
        private readonly changeDetectorRef: ChangeDetectorRef
    ) {
        this.appState = this.appService.appState;

        EventHelper.subscribe(this.router.events, this.routerUpdated, this);
    }

    public readonly appIcons: typeof AppIcons = AppIcons;

    public activePerson?: Person;

    public activeAccount?: AccountDto;

    public appState: AppState;

    public menuOptions?: MenuOptions;

    private sessionChangedSubscription?: Subscription;

    public title: string = $localize`:@@header.defaultTitle:Loading...` as string;

    public loading: boolean = false;

    public ngOnInit(): void {
        this.activePerson = this.sessionService.activePerson;
        this.sessionChangedSubscription = EventHelper.subscribe(this.sessionService.sessionChanged, this.sessionChanged, this);
        this.menuOptions = this.menuService.menuOptions;

        this.updateTitle();
    }

    public ngOnDestroy(): void {
        this.sessionChangedSubscription = EventHelper.unsubscribe(this.sessionChangedSubscription);
    }

    private routerUpdated(event: any): void {
        switch (true) {
            case event instanceof NavigationStart: {
                this.loading = true;
                break;
            }
            case event instanceof NavigationEnd:
            case event instanceof NavigationCancel:
            case event instanceof NavigationError: {
                this.loading = false;
                break;
            }
            default: {
                break;
            }
        }
    }

    private updateTitle(): void {
        if (!this.activeAccount) {
            this.title = "";
        } else {
            this.title = `${this.activeAccount.name} - CyanAudit`;
        }
    }

    private sessionChanged(sessionData: SessionChangedEventData): void {
        this.activePerson = sessionData.activePerson;
        this.activeAccount = sessionData.activeAccount;
        this.updateTitle();
    }

    public async quickAdd(): Promise<void> {
        await this.dialogService.showModal({ component: QuickAddComponent });
    }

    public debug(): void {
        this.intentService.executeIntent(ShowDebugDialogIntent);
    }

    public share(event: Event): void {
        this.dialogService.openOverlay(ShareUrlComponent, undefined, event, false, true).then();
    }

    public loggedOutLogoClick(event: MouseEvent): void {
        event.stopPropagation();

        if (this.navigationService.currentPage != Pages.dashboard) {
            this.navigateToStart();
            return;
        }

        this.openProfileMenu(event);
    }

    public openProfileMenu(event: MouseEvent): void {
        event.stopPropagation();

        this.dialogService.showPopover({
            component: AccountPopoverComponent,
            event: event
        }).then();
    }

    public openAccountSwitchMenu(event: MouseEvent): void {
        event.stopPropagation();

        this.dialogService.showPopover({
            component: AccountSwitchPopoverComponent,
            event: event
        }).then();
    }

    public navigateToStart(): void {
        this.navigationService.navigate(Pages.dashboard).then();
    }

    public restart(): void {
        location.reload();
    }

    public async takeScreenshot(triggeredEventArguments: ProcessElementTriggeredEventArguments): Promise<void> {
        this.changeDetectorRef.detectChanges();
        let blob: Blob|undefined;
        try {
            const canvas: HTMLCanvasElement|undefined = await this.screenshotService.takeScreenshot();
            if (canvas) {
                blob = await ImageHelper.canvasToBlob(canvas, "image/png", 1.0);
            }
        } catch (error) {
            await this.dialogService.showError(error);
            return;
        }

        if (!blob) {
            return;
        }

        try {
            if ("share" in navigator) {
                try {
                    const file: File = new File([blob], `screenshot-${this.timeService.currentTimestamp}.jpg`, { type: "image/png" });
                    await navigator.share({ files: [file] });
                    return;
                } catch (error) {
                    console.warn(error);
                    // Ignore error and use classic download.
                }
            }

            WebHelper.downloadBlob(blob, `screenshot-${this.timeService.currentTimestamp}.png`);
        } finally {
            triggeredEventArguments.finishedReceiver.finished();
        }
    }

    public async openHelpMenu(event: Event): Promise<void> {
        enum HelpMenuOptions {
            help,
            searchHelp,
            feedback,
            support
        }

        const menuItems: Array<ContextMenuItem> = [];
        menuItems.push(new ContextMenuItem($localize`:@@header.openHelp:Open help` as string, {
            tag: HelpMenuOptions.help,
            icon: AppIcons.help
        }));
        menuItems.push(new ContextMenuItem($localize`:@@header.searchHelp:Search help` as string, {
            tag: HelpMenuOptions.searchHelp,
            icon: AppIcons.genericSearch
        }));
        menuItems.push(new ContextMenuItem("-", {
            isDivider: true
        }));
        menuItems.push(new ContextMenuItem($localize`:@@header.giveFeedback:Give feedback` as string, {
            tag: HelpMenuOptions.feedback,
            icon: AppIcons.feedback
        }));
        menuItems.push(new ContextMenuItem($localize`:@@header.createSupportTicket:Create support ticket` as string, {
            tag: HelpMenuOptions.support,
            icon: AppIcons.support
        }));

        const result: ContextMenuItem|undefined = await this.dialogService.showContextMenu(menuItems, event, false);
        switch (result?.tag) {
            case HelpMenuOptions.help:
                this.openHelp();
                break;
            case HelpMenuOptions.searchHelp:
                await this.searchHelp();
                break;
            case HelpMenuOptions.feedback:
                this.openFeedback();
                break;
            case HelpMenuOptions.support:
                this.openSupport();
                break;
        }
    }

    public openHelp(): void {
        this.onlineHelpService.openHelp();
    }

    public openFeedback(): void {
        window.open("mailto:support@cyanaudit.com?subject=Feedback CyanAudit 2.26.1+1105.scroll-to-next");
    }

    public openSupport(): void {
        window.open("mailto:support@cyanaudit.com?subject=Support CyanAudit 2.26.1+1105.scroll-to-next");
    }

    public async searchHelp(): Promise<void> {
        const searchQuery: string|undefined = await this.dialogService.showInputPrompt($localize`:@@app.searchHelpTitle:Search help`);
        if (searchQuery) {
            this.onlineHelpService.searchHelp(searchQuery);
        }
    }

}
